import { forwardRef, PropsWithChildren, useEffect, useState } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

import { TVStyleProps } from '../../style-system';

import { imageStyles } from './image.styles';

type ImageProps = PropsWithChildren<
    Omit<NextImageProps, 'fill'> &
        TVStyleProps<typeof imageStyles> & {
            fallbackImage?: string;
        }
>;

const Image = forwardRef<HTMLImageElement, ImageProps>(
    ({ src, alt = '', width, height, fallbackImage, ...rest }: ImageProps, ref) => {
        const [error, setError] = useState(false);

        useEffect(() => {
            setError(false);
        }, [src]);

        return (
            <NextImage
                src={error && fallbackImage ? fallbackImage : src}
                width={width}
                height={height}
                className={imageStyles(rest)}
                ref={ref}
                alt={alt}
                onError={() => setError(true)}
            />
        );
    },
);

Image.displayName = 'Image';

export type { ImageProps };
export { Image };
