import React, { PropsWithChildren } from 'react';

import * as SessionComponents from '@blockworks/session/components';

import homeScreenshot from '@/assets/images/home.png';
import { ILayoutProps } from '@/interfaces/layout';

import { UserDropdown } from '../global/top-bar/user-dropdown';
import { LayoutHead } from '../layout-head';

type AuthLayoutProps = PropsWithChildren<
    Omit<ILayoutProps, 'layoutProps'> & Omit<SessionComponents.AuthLayoutProps, 'components'>
>;

const AuthLayout = ({ children, metaProps, additionalScripts, ...rest }: AuthLayoutProps) => {
    return (
        <SessionComponents.AuthLayout
            components={{
                head: <LayoutHead metaProps={metaProps} additionalScripts={additionalScripts} />,
                userDropdown: () => <UserDropdown hideMenuLinks />,
            }}
            sidebar={{ image: homeScreenshot.src }}
            {...rest}
        >
            {children}
        </SessionComponents.AuthLayout>
    );
};

export { AuthLayout };
