import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const imageStyles = tVariants({
    variants: {
        square: { true: 'aspect-square' },
        cover: { true: 'object-cover' },
        object: {
            center: 'object-center',
        },
        ...factoryStyleProps.size,
        ...factoryStyleProps.layout,
        ...factoryStyleProps.border,
        ...factoryStyleProps.__atoms.layout.flex,
    },
});

export { imageStyles };
