import { FlexBox, Link, Text } from '@blockworks/ui/components';

import { AccountSignInForm } from '@/components/auth/account-sign-in-form';
import { AuthLayout } from '@/layout/auth/auth-layout';
import { IMetaProps } from '@/layout/default-meta';

export const getServerSideProps = async () => {
    return {
        props: {
            meta: {
                title: 'Sign In | Blockworks Research',
            },
        },
    };
};

export const SignInPage = () => (
    <AccountSignInForm center flexGrow={1}>
        <FlexBox gap="xs" center w="full">
            <Text size="sm" color="muted">
                {`Don't have an account?`}
            </Text>
            <Link intent="primary" size="sm" fontWeight="medium" href="/sign-up">
                Sign up
            </Link>
        </FlexBox>
    </AccountSignInForm>
);

SignInPage.getLayout = (page: React.ReactElement, metaProps: IMetaProps) => (
    <AuthLayout
        metaProps={metaProps}
        headingText="Sign in to your account"
        subheadingText="Crypto's most powerful research platform."
    >
        {page}
    </AuthLayout>
);

export default SignInPage;
