import React, { PropsWithChildren } from 'react';

import { FlexBox, Image, Layout, Logo, Text } from '@blockworks/ui/components';
import { ColorModeClass } from '@blockworks/ui/models';

import { useAuth } from '../context/auth';

type AuthLayoutProps = PropsWithChildren<{
    headingText: string;
    subheadingText: string;
    col?: boolean;
    yAlignCenter?: boolean;
    sidebar?: {
        content?: React.ReactNode;
        footerContent?: React.ReactNode;
        contentPosition?: 'left' | 'right';
        /** Only displayed when `sidebarContentPosition` is set to `right` */
        image?: string;
    };
    components?: {
        /** By default, the logo is the Research logo. Pass a component here to override it.  */
        logo?: React.ReactNode;
        /** A custom html `head` component for managing page metadata and scripts */
        head?: React.ReactNode;
        /** A slot for a user dropdown component that sits above the content passed as `children`. */
        userDropdown?: () => JSX.Element;
    };
}>;

const AuthLayout = ({
    sidebar,
    headingText,
    subheadingText,
    col,
    yAlignCenter = false,
    children,
    components,
}: AuthLayoutProps) => {
    const { status } = useAuth();
    const { userDropdown: UserDropdown, head, logo } = components ?? {};
    const {
        content: sidebarContent,
        footerContent: sidebarFooterContent,
        contentPosition: sidebarContentPosition = 'right',
        image: sidebarImage,
    } = sidebar ?? {};

    return (
        <Layout>
            {head}
            <FlexBox h="full" minH="full">
                <div className={`w-[50%] xl:w-[42%] ${ColorModeClass.Dark} hidden lg:flex`}>
                    <FlexBox w="full" col={col} bgColor="muted" color="base" pos="relative" overflow="hidden">
                        <div
                            className={`relative h-full flex items-center w-full pl-[calc(max(calc(100vw_/_2)_-_calc(1440px_/_2),4.5rem))] pr-10' pr-10 py-24`}
                        >
                            <div className="absolute top-6">{logo ?? <Logo />}</div>
                            {sidebarContentPosition === 'left' ? (
                                <SideBarContent
                                    yAlignCenter={yAlignCenter}
                                    headingText={headingText}
                                    subheadingText={subheadingText}
                                    content={sidebarContent}
                                />
                            ) : null}
                        </div>
                        {sidebarFooterContent ? (
                            <div
                                className={`flex 'pl-[calc(max(calc(100vw_/_2)_-_calc(1440px_/_2),4.5rem))] pr-10' mt-auto mb-10 pr-16`}
                            >
                                {sidebarFooterContent}
                            </div>
                        ) : null}
                        {sidebarContentPosition === 'right' && sidebarImage && (
                            <div className="sm:hidden md:block absolute border-8 border-solid border-grey-500 rounded-2xl top-[25%] -right-20 shadow-3xl">
                                <Image
                                    src={sidebarImage}
                                    alt="homepage screenshot"
                                    width={500}
                                    height={500}
                                    borderRadius="md"
                                />
                            </div>
                        )}
                    </FlexBox>
                </div>
                <div className="w-[100%] lg:w-[50%] xl:w-[58%] flex flex-col lg:flex-row">
                    <div className={`w-[100%] flex ${ColorModeClass.Dark} xs:flex lg:hidden`}>
                        <FlexBox w="full" bgColor="muted" color="base" pos="relative" py={6} px={5}>
                            <Logo />
                        </FlexBox>
                    </div>
                    <div className="relative flex justify-center items-center w-full lg:pr-[calc(max(calc(100vw_/_2)_-_calc(1440px_/_2),4.5rem))] px-10 py-12">
                        <div className="w-[min(100%,400px)] max-w-full min-h-[min(400px,100%)] ">
                            {status === 'authenticated' && UserDropdown && (
                                <FlexBox justifyContent="start" gap="xl" pb={5}>
                                    <UserDropdown />
                                </FlexBox>
                            )}
                            {sidebarContentPosition === 'right' && sidebarContent ? (
                                <SideBarContent
                                    yAlignCenter={yAlignCenter}
                                    headingText={headingText}
                                    subheadingText={subheadingText}
                                    content={sidebarContent}
                                />
                            ) : null}
                            <FlexBox>{children}</FlexBox>
                        </div>
                    </div>
                </div>
            </FlexBox>
        </Layout>
    );
};

type SideBarContentProps = Pick<AuthLayoutProps, 'headingText' | 'subheadingText' | 'yAlignCenter'> & {
    content?: React.ReactNode;
};

const SideBarContent = ({ headingText, subheadingText, yAlignCenter, content }: SideBarContentProps) => {
    return (
        <div className={`flex h-[min(400px,100%)] max-w-full pt-6 ${yAlignCenter ? 'items-center' : ''}`}>
            <FlexBox col gap="md" maxW="full">
                <FlexBox col gap="md" pr={5}>
                    <Text size="display-sm" weight="medium">
                        {headingText}
                    </Text>
                    <Text color="deselect" size="md">
                        {subheadingText}
                    </Text>
                </FlexBox>
                {content}
            </FlexBox>
        </div>
    );
};

export type { AuthLayoutProps };
export { AuthLayout };
